var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3f19af0c4ef8bd1c441e6af20fa149bd8d66201a"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

Sentry.init({
  environment: process.env.NEXT_PUBLIC_APP_ENV,
  dsn: "https://8b78f904dd954daca48a68698ac8321d@o553366.ingest.sentry.io/6094602",
  tracesSampleRate: 0.0,
  enabled: process.env.NEXT_PUBLIC_SENTRY_LOGGING === "enabled",
});
